@import url('https://fonts.googleapis.com/css2?family=Bricolage+Grotesque:opsz,wght@12..96,200..800&display=swap');

@font-face {
    font-family: 'ApfelGrotezk';
    src: url('../public/fonts/ApfelGrotezk-Regular.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
}

h1 {
    text-align: center;
}


body {
    background-color: #fff9eb;
    background-image: url(/src/img/bg-left.svg), url(/src/img/bg-right.svg);
    background-attachment: fixed !important;
    background-position: bottom left, center right;
    background-attachment: fixed, fixed !important;
    background-size: auto 433px, auto 433px;
    height: 100vh !important;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
}

.btnesa {
    padding: 30px 0;
    display: flex;
    justify-content: center;
    border-radius: 5px;
    background-color: #F4EAD7;
}

h1 {
    text-align: center;
}

.h2 {
    font-family: "Bricolage Grotesque", sans-serif;
    font-size: 45px;
    font-style: normal;
    font-weight: 800;
    line-height: 40px;
    letter-spacing: -1.35px;
}

.h2-blog {
    font-family: "Bricolage Grotesque", sans-serif;
    font-size: 30px;
    font-style: normal;
    font-weight: 800;
    line-height: 96%;
    letter-spacing: -0.9px;
}

.h3 {
    font-family: "Bricolage Grotesque", sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 800;
    line-height: 20px;
    letter-spacing: -0.6px;
}

.h3-footer {
    font-family: "Bricolage Grotesque", sans-serif;
    font-size: 17px;
    font-style: normal;
    font-weight: 800;
    line-height: 18px;
    letter-spacing: -0.51px;
}

p {
    font-family: 'ApfelGrotezk', sans-serif;
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
}

.homepage h1.h3 {
    width: 90%;
}

.return {
    display: none;
}

.return .h3-footer {
    display: none;
}

.grid-btn {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 16px;
    row-gap: 10px;
    margin: 48px 0;
    width: 100%;
}

.homepage .logo img {
    margin-bottom: 22px;
    height: 63px;
}

.offers .logo img {
    margin-bottom: 75px;
    height: 45px;
}

.container {
    max-width: 964px;
    width: 964px;
}

.gap-10 {
    gap: 10px;
    display: flex;
    flex-direction: column;
}

.icon-social-network {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 20px;
}

.offers.container,
.homepage.container {
    margin: 0;
}

.card-border {
    padding: 26px 28px;
    border-radius: 5px;
    background-color: #fff9eb;
    border: 1px solid #FF6F36;
}

.card-border h2 {
    margin-bottom: 15px;
}

.offers h2.h2 {
    font-size: 33px;
    margin-top: 15px;
}

@media (max-width: 992px) {
    /* .icon-social-network {
        margin-bottom: 64px;
    } */

    .homepage h1 {
        font-family: "Bricolage Grotesque", sans-serif;
        font-size: 30px;
        font-style: normal;
        font-weight: 800;
        line-height: 96%;
        letter-spacing: -0.9px;
    }

    body {
        height: revert !important;
        background-image: url(/src/img/bg-left-tab.svg), url(/src/img/bg-right-tab.svg);
        background-position: top left, bottom right;
        background-attachment: fixed, fixed !important;
        background-size: auto 633px, auto 633px;
    }

    .offers h2.h2 {
        font-size: 25px;
        margin-top: 35px;
        text-align: center;
        line-height: normal;
    }

    .return {
        display: flex;
        align-items: center;
        width: 100%;
        gap: 20px;
        margin-bottom: 213px;
        position: revert;
    }

    .return .h3-footer {
        display: flex;
    }

    body.offers-active {
        background-position: bottom left, top right;
        align-items: flex-start;
        /* margin-top: 83px; */
    }

    .grid-btn {
        display: grid;
        grid-template-columns: 1fr;
        row-gap: 16px;
        margin: 60px 0;
    }

    .homepage.container {
        margin: 250px 0;
    }

    .offers.container {
        margin: 83px 0;
    }

    .offers .grid-btn {
        margin: 50px 0 0 0;
    }

    .homepage .logo img {
        margin-bottom: 28px;
        height: 133px;
    }

    .offers .logo img {
        margin-bottom: 51px;
        height: 60px;
        margin-top: 0;
    }

    .container {
        max-width: 604px;
        margin: 0;
    }

    .homepage h1.h3 {
        width: 70%;
    }

    .offers h1.h2 {
        text-align: center;
        width: 75%;
    }
}

@media (max-width: 576px) {
    body {
        background-color: #fff9eb;
        background-image: url(/src/img/bg-mob.svg);
        background-position: bottom 10% left;
        background-size: auto 620px;
        background-attachment: fixed !important;
        background-repeat: no-repeat;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .offers.container,
    .homepage.container {
        margin: 64px 0;
    }

    .offers h1 {
        font-size: 30px;
        line-height: 96%;
        letter-spacing: -0.9px;
    }

    .homepage h1 {
        font-family: "Bricolage Grotesque", sans-serif;
        font-size: 20px;
        font-style: normal;
        font-weight: 800;
        line-height: 20px;
        letter-spacing: -0.6px;
    }

    .container {
        max-width: 321px;
        margin: 64px 0;
    }

    .grid-btn {
        display: grid;
        row-gap: 10px;
        margin: 40px 0;
        width: 100%;
    }

    .homepage .logo img {
        margin-bottom: 22px;
        height: 63px;
    }

    .offers .logo img {
        height: 30px;
        margin-bottom: 40px;
        margin-top: 5px;
    }

    .return {
        display: flex;
        width: 321px;
        position: absolute;
    }

    .return .h3-footer {
        display: none;
    }

    .homepage h1.h3 {
        width: 90%;
    }
}